import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faLaptop, faChartLine, faGraduationCap, faBullhorn, faBrain, faChevronUp, faChevronDown, faArrowRight } from '@fortawesome/free-solid-svg-icons';


const CTAButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  background: linear-gradient(135deg, var(--main-brand-color) 0%, var(--dark-accent) 100%);
  color: var(--white);
  border: none;
  border-radius: 25px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 2rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, var(--dark-accent) 0%, var(--main-brand-color) 100%);
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);

    &::before {
      opacity: 1;
    }
  }

  .icon {
    margin-left: 8px;
    position: relative;
    z-index: 2;
    transition: transform 0.3s ease;
  }

  span {
    position: relative;
    z-index: 2;
  }

  &:hover .icon {
    transform: translateX(5px);
  }
`;

const CTAContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`;

const PageContainer = styled.div`
  min-height: 100vh;
  background: linear-gradient(135deg, var(--white) 0%, var(--light-shade) 100%);
  display: flex;
  position: relative;
`;

const Sidebar = styled.nav`
  width: 280px;
  background: var(--dark-shade);
  height: 100vh;
  position: fixed;
  z-index: 100;
  box-shadow: 4px 0 15px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    background: rgba(var(--dark-shade-rgb), 0.95);
    backdrop-filter: blur(10px);
    box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    transform: translateY(${props => props.isOpen ? '0' : '90%'});
  }
`;

const MobileToggle = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    background: var(--dark-shade);
    color: var(--white);
    cursor: pointer;
    border-radius: 15px 15px 0 0;
    
    .toggle-icon {
      transition: transform 0.3s ease;
      transform: rotate(${props => props.isOpen ? '180deg' : '0deg'});
    }
  }
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 2rem 0;
  width: 100%;
  height: calc(100% - 4rem);
  overflow-y: auto;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    max-height: 70vh;
    gap: 0.5rem;
  }

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--main-brand-color);
    border-radius: 3px;
  }
`;

const MenuItem = styled.li`
  padding: 1.2rem 1.5rem;
  color: var(--white);
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  border-left: 4px solid transparent;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  ${props => props.active && `
    background: linear-gradient(90deg, var(--main-brand-color) 0%, transparent 100%);
    border-left-color: var(--main-brand-color);
  `}

  .icon {
    width: 24px;
    margin-right: 1rem;
    transition: transform 0.3s ease;
  }

  &:hover .icon {
    transform: scale(1.1);
  }

  .text {
    font-weight: 500;
    font-size: 1.1rem;
  }

  @media (max-width: 768px) {
    padding: 0.8rem 1rem;
    border-radius: 12px;
    border-left: none;
    background: ${props => props.active ? 'linear-gradient(135deg, var(--main-brand-color) 0%, var(--dark-accent) 100%)' : 'rgba(255, 255, 255, 0.1)'};

    &:hover {
      background: ${props => props.active ? 'linear-gradient(135deg, var(--main-brand-color) 0%, var(--dark-accent) 100%)' : 'rgba(255, 255, 255, 0.15)'};
      transform: translateX(5px);
    }
  }
`;

const Content = styled.div`
  flex: 1;
  margin-left: 280px;
  padding: 4rem 2rem;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-bottom: 80px;
    padding: 2rem 1rem;
  }
`;

const ServiceContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const ServiceTitle = styled.h1`
  font-size: 3rem;
  color: var(--dark-shade);
  margin-bottom: 2rem;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100px;
    height: 4px;
    background-color: var(--main-brand-color);
    margin-top: 1rem;
  }

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const ServiceSection = styled.section`
  margin-bottom: 3rem;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  color: var(--dark-accent);
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const Description = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--dark-shade);
  margin-bottom: 1.5rem;
`;
const NavigationButtons = styled.div`
  position: fixed;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 90;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavButton = styled.button`
  background-color: var(--dark-shade);
  color: var(--white);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: var(--main-brand-color);
    transform: scale(1.1);
  }
`;

const servicesData = [
  {
    id: 'customer-experience',
    icon: faUsers,
    title: 'Experiencia del Cliente',
    why: 'La experiencia del cliente es un factor clave que determina la fidelización y el crecimiento a largo plazo. Una interacción positiva genera clientes leales y fortalece tu reputación en el mercado.',
    details: 'Definimos y analizamos a tu público objetivo para personalizar cada interacción. Diseñamos estrategias que optimizan el recorrido del cliente, desde el primer contacto hasta la postventa, para asegurar una experiencia memorable.',
    benefits: [
      'Retención de clientes mejorada',
      'Incremento en el valor del cliente a largo plazo',
      'Reducción en costos de adquisición de clientes',
      'Mayor satisfacción del cliente'
    ],
    example: {
      title: 'Ejemplo Práctico',
      description: 'Integramos un sistema CRM que permite a tu equipo rastrear el historial de compras y preferencias de los clientes. Esto facilita el envío automático de recomendaciones personalizadas y ofertas exclusivas según sus intereses, fortaleciendo su relación con la marca.'
    }
  },
  {
    id: 'ai-consulting',
    icon: faBrain,
    title: 'Consultoría en IA y Automatización',
    why: 'La inteligencia artificial y la automatización son herramientas esenciales para reducir tareas manuales, aumentar la eficiencia y tomar decisiones estratégicas basadas en datos.',
    details: 'Implementamos soluciones de IA para automatizar tareas repetitivas, mejorar la eficiencia operativa y optimizar la toma de decisiones. Identificamos oportunidades clave para transformar procesos internos utilizando tecnología avanzada.',
    benefits: [
      'Ahorro de tiempo en tareas administrativas',
      'Respuestas más rápidas para los clientes',
      'Mejora en la productividad del equipo',
      'Procesos optimizados'
    ],
    example: {
      title: 'Ejemplo Práctico',
      description: 'Implementamos un chatbot en tu sitio web que responde automáticamente a preguntas frecuentes, como horarios de atención, estado de pedidos o información de productos. Esto libera tiempo a tu equipo para enfocarse en consultas más complejas y mejora la experiencia del cliente.'
    }
  },
  {
    id: 'marketing-sales',
    icon: faBullhorn,
    title: 'Marketing y Ventas',
    why: 'Las estrategias digitales bien diseñadas no solo incrementan la visibilidad de tu negocio, sino que también generan leads cualificados y convierten visitantes en clientes.',
    details: 'Diseñamos estrategias integrales que incluyen SEO, creación de contenido viral y embudos de venta. Estas herramientas ayudan a captar y convertir clientes, fortaleciendo tu presencia online.',
    benefits: [
      'Mayor alcance y tráfico a tu web',
      'Generación de leads cualificados',
      'Procesos de venta más efectivos',
      'Incremento en las tasas de conversión'
    ],
    example: {
      title: 'Ejemplo Práctico',
      description: 'Creamos un embudo de ventas digital que incluye anuncios en redes sociales, una página de aterrizaje con contenido atractivo y un sistema de correos automatizados que guía a los leads desde el interés inicial hasta la compra final.'
    }
  },
  {
    id: 'advanced-tech',
    icon: faLaptop,
    title: 'Tecnología Avanzada',
    why: 'La tecnología avanzada permite a las empresas operar de manera más eficiente, centralizar datos y mejorar la toma de decisiones.',
    details: 'Integramos herramientas como CRM, ERP, sistemas de facturación y chatbots para optimizar la comunicación, la gestión de datos y los procesos internos.',
    benefits: [
      'Operaciones más fluidas y eficientes',
      'Menor riesgo de errores humanos',
      'Mejor coordinación entre departamentos',
      'Preparación para el crecimiento futuro'
    ],
    example: {
      title: 'Ejemplo Práctico',
      description: 'Implementamos un sistema ERP que centraliza la gestión de inventarios, pedidos y finanzas. Esto asegura que todas las áreas de tu empresa trabajen de manera integrada, reduciendo errores y tiempos de respuesta.'
    }
  },
  {
    id: 'agile-growth',
    icon: faChartLine,
    title: 'Crecimiento Ágil',
    why: 'En un entorno empresarial cambiante, las empresas deben ser ágiles para responder rápidamente a las demandas del mercado y mantenerse competitivas.',
    details: 'Aplicamos metodologías ágiles y gestionamos proyectos a través de PMOs para asegurar que tu empresa pueda adaptarse rápidamente a nuevas oportunidades o desafíos.',
    benefits: [
      'Mayor adaptabilidad al mercado',
      'Ejecución más rápida de proyectos',
      'Mejora en la colaboración del equipo',
      'Reducción de tiempos y costos'
    ],
    example: {
      title: 'Ejemplo Práctico',
      description: 'Diseñamos un modelo de gestión basado en sprints de dos semanas para proyectos internos. Esto permite a tu equipo revisar el progreso regularmente, ajustar prioridades y mantener una comunicación clara sobre los objetivos.'
    }
  },
  {
    id: 'training-kpis',
    icon: faGraduationCap,
    title: 'Formación y KPIs',
    why: 'Un equipo capacitado y alineado con los objetivos del negocio es esencial para alcanzar resultados medibles y tomar decisiones informadas.',
    details: 'Ofrecemos formación especializada y establecemos indicadores clave de desempeño (KPIs) para monitorear el progreso y asegurar que tu equipo esté enfocado en los objetivos prioritarios.',
    benefits: [
      'Equipo más preparado y eficiente',
      'Mejora en la toma de decisiones estratégicas',
      'Seguimiento claro de los objetivos del negocio',
      'Incremento en la productividad general'
    ],
    example: {
      title: 'Ejemplo Práctico',
      description: 'Organizamos sesiones de capacitación para tu equipo de ventas, enseñándoles a utilizar un CRM de manera eficiente. También definimos KPIs clave, como la tasa de conversión y el tiempo promedio de respuesta, para medir su desempeño.'
    }
  }
];

const CaseStudy = styled.div`
  background: linear-gradient(135deg, var(--main-brand-color) 0%, var(--dark-accent) 100%);
  padding: 2rem;
  border-radius: 20px;
  color: var(--white);
  margin-top: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transform: translateY(0);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  ${SectionTitle} {
    color: var(--white);
  }

  ${Description} {
    color: var(--white);
    opacity: 0.9;
  }
`;

const ServiceDetails = () => {
  const [currentService, setCurrentService] = useState(servicesData[0]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleServiceChange = (service) => {
    setCurrentService(service);
    if (window.innerWidth <= 768) {
      setIsMobileMenuOpen(false);
    }
  };

  const navigateService = (direction) => {
    const currentIndex = servicesData.findIndex(s => s.id === currentService.id);
    let newIndex;
    if (direction === 'next') {
      newIndex = (currentIndex + 1) % servicesData.length;
    } else {
      newIndex = currentIndex - 1 < 0 ? servicesData.length - 1 : currentIndex - 1;
    }
    setCurrentService(servicesData[newIndex]);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <PageContainer>
      <Sidebar isOpen={isMobileMenuOpen}>
        <MobileToggle isOpen={isMobileMenuOpen} onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
          <FontAwesomeIcon icon={isMobileMenuOpen ? faChevronDown : faChevronUp} className="toggle-icon" />
        </MobileToggle>
        <MenuList>
          {servicesData.map((service) => (
            <MenuItem
              key={service.id}
              active={currentService.id === service.id}
              onClick={() => handleServiceChange(service)}
            >
              <FontAwesomeIcon icon={service.icon} className="icon" />
              <span className="text">{service.title}</span>
            </MenuItem>
          ))}
        </MenuList>
      </Sidebar>

      <Content>
        <ServiceContent>
          <ServiceTitle>{currentService.title}</ServiceTitle>
          
  <ServiceSection ServiceSection >
            <SectionTitle>¿Por qué es importante?</SectionTitle>
            <Description>{currentService.why}</Description>
          </ServiceSection>

          <ServiceSection>
            <SectionTitle>¿Qué hacemos?</SectionTitle>
            <Description>{currentService.details}</Description>
          </ServiceSection>

          <ServiceSection>
            <SectionTitle>Beneficios</SectionTitle>
            <ul>
              {currentService.benefits.map((benefit, index) => (
                <Description key={index}>• {benefit}</Description>
              ))}
            </ul>
          </ServiceSection>

          <CaseStudy>
            <SectionTitle>{currentService.example.title}</SectionTitle>
            <Description>{currentService.example.description}</Description>
          </CaseStudy>

                      <CTAContainer>
              <CTAButton onClick={() => window.location.href = '/contacto'}>
                <span>Consigue resultados similares</span>
                <FontAwesomeIcon icon={faArrowRight} className="icon" />
              </CTAButton>
            </CTAContainer>

</ServiceContent>
      </Content>

      <NavigationButtons>
        <NavButton onClick={() => navigateService('prev')}>
          <FontAwesomeIcon icon={faChevronUp} />
        </NavButton>
        <NavButton onClick={() => navigateService('next')}>
          <FontAwesomeIcon icon={faChevronDown} />
        </NavButton>
      </NavigationButtons>
    </PageContainer>
  );
};

export default ServiceDetails;


