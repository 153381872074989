import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faDiagnoses, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const BenefitsSection = styled.section`
  padding: 8rem 0;
  background: linear-gradient(135deg, var(--light-shade) 0%, var(--white) 100%);
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, transparent, var(--main-brand-color), transparent);
  }

  @media (max-width: 768px) {
    padding: 4rem 1rem;
  }
`;

const BenefitsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2.5rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 1rem;
  }
`;

const BenefitCard = styled.div`
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 24px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  padding: 3rem 2.5rem;
  text-align: center;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.2);

  &:hover {
    transform: translateY(-10px) scale(1.02);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
    background: white;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      135deg,
      rgba(var(--main-brand-color-rgb), 0.1) 0%,
      rgba(var(--dark-accent-rgb), 0.1) 100%
    );
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::before {
    opacity: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(
      to right,
      var(--main-brand-color),
      var(--dark-accent)
    );
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }

  &:hover::after {
    transform: scaleX(1);
  }
`;

const IconWrapper = styled.div`
  font-size: 3.5rem;
  color: var(--main-brand-color);
  margin-bottom: 2rem;
  position: relative;
  z-index: 1;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background: linear-gradient(135deg, var(--main-brand-color) 0%, var(--dark-accent) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));

  ${BenefitCard}:hover & {
    transform: scale(1.2) rotate(10deg);
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
  }
`;

const BenefitTitle = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: var(--dark-accent);
  position: relative;
  z-index: 1;
  font-weight: 700;
  background: linear-gradient(135deg, var(--dark-accent) 0%, var(--main-brand-color) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease;

  ${BenefitCard}:hover & {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

const BenefitDescription = styled.p`
  font-size: 1.1rem;
  color: var(--dark-shade);
  line-height: 1.8;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease;
  padding: 0 1rem;

  ${BenefitCard}:hover & {
    color: var(--dark-accent);
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const SectionTitle = styled.h2`
  font-size: 3.5rem;
  text-align: center;
  margin-bottom: 4rem;
  color: var(--dark-accent);
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: linear-gradient(135deg, var(--dark-accent) 0%, var(--main-brand-color) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &::after {
    content: '';
    display: block;
    width: 120px;
    height: 4px;
    background: linear-gradient(90deg, var(--main-brand-color), var(--dark-accent));
    margin: 1.5rem auto 0;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    font-size: 2.5rem;
    margin-bottom: 3rem;
  }
`;

const Benefits = () => {
  const benefitsRef = useRef(null);

  useEffect(() => {
    const cards = benefitsRef.current.querySelectorAll('.benefit-card');
    // gsap.from(cards, {
    //   opacity: 0,
    //   y: 50,
    //   stagger: 0.2,
    //   duration: 0.8,
    //   ease: 'power3.out',
    //   scrollTrigger: {
    //     trigger: benefitsRef.current,
    //     start: 'top 80%',
    //   },
    // });
  }, []);

  return (
    <BenefitsSection ref={benefitsRef}>
      <SectionTitle>Beneficios del diagnóstico</SectionTitle>
      <BenefitsContainer>
        <BenefitCard className="benefit-card">
          <IconWrapper>
            <FontAwesomeIcon icon={faRocket} />
          </IconWrapper>
          <BenefitTitle>Optimización de Procesos y Automatización</BenefitTitle>
          <BenefitDescription>
            Descubrimos oportunidades clave para que tu negocio funcione de forma más eficiente mediante la digitalización de procesos críticos y la automatización de tareas manuales, ahorrando tiempo y recursos.
          </BenefitDescription>
        </BenefitCard>
        <BenefitCard className="benefit-card">
          <IconWrapper>
            <FontAwesomeIcon icon={faDiagnoses} />
          </IconWrapper>
          <BenefitTitle>Evaluación de tus Herramientas Digitales</BenefitTitle>
          <BenefitDescription>
            Analizamos las herramientas que ya usas y evaluamos su rendimiento para identificar qué puede mejorarse y cómo puedes optimizar o actualizar tu tecnología actual para el crecimiento.
          </BenefitDescription>
        </BenefitCard>
        <BenefitCard className="benefit-card">
          <IconWrapper>
            <FontAwesomeIcon icon={faChartLine} />
          </IconWrapper>
          <BenefitTitle>Consultoría de negocio</BenefitTitle>
          <BenefitDescription>
            Proporcionamos recomendaciones estratégicas y prácticas para aumentar tus ventas y mejorar la eficiencia general del negocio.
          </BenefitDescription>
        </BenefitCard>
      </BenefitsContainer>
    </BenefitsSection>
  );
};

export default Benefits;
