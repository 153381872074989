import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faLaptop, faChartLine, faGraduationCap, faBullhorn, faBrain, faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const ServicesSection = styled.section`
  padding: 6rem 0;
  background: linear-gradient(135deg, var(--white) 0%, var(--light-shade) 100%);
  overflow: hidden;
`;

const SectionTitle = styled.h2`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  color: var(--dark-shade);
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100px;
    height: 4px;
    background-color: var(--main-brand-color);
    margin: 1rem auto 0;
  }

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const ServiceItem = styled.div`
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 2.5rem;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
  }

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::before {
    opacity: 1;
  }
`;

const ServiceTitle = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: var(--dark-shade);
  font-weight: bold;
  position: relative;
  z-index: 1;

  span {
    position: relative;
    display: inline-block;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%;
      height: 3px;
      background-color: var(--main-brand-color);
      transform: scaleX(0);
      transition: transform 0.3s ease;
    }
  }

  &:hover span::after {
    transform: scaleX(1);
  }
`;

const ServiceBrief = styled.p`
  font-size: 1.1rem;
  color: var(--dark-accent);
  margin-bottom: 1rem;
  font-weight: 500;
  position: relative;
  z-index: 1;
`;

const ServiceDescription = styled.p`
  font-size: 1rem;
  color: var(--dark-shade);
  line-height: 1.6;
  position: relative;
  z-index: 1;
  margin-bottom: 1.5rem;
`;

const IconWrapper = styled.div`
  font-size: 3.5rem;
  color: var(--main-brand-color);
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;
`;

const AIServiceItem = styled(ServiceItem)`
  background: linear-gradient(135deg, var(--main-brand-color) 0%, var(--dark-accent) 100%);
  color: var(--white);

  ${ServiceTitle}, ${ServiceBrief}, ${ServiceDescription} {
    color: var(--white);
  }

  ${IconWrapper} {
    color: var(--white);
  }
`;

const LearnMoreButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1.5rem;
  background: linear-gradient(135deg, var(--main-brand-color) 0%, var(--dark-accent) 100%);
  color: var(--white);
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, var(--dark-accent) 0%, var(--main-brand-color) 100%);
    opacity: 0;
    z-index: -1;
    transition: opacity 0.3s ease;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    
    &::before {
      opacity: 1;
    }

    .arrow-icon {
      transform: translateX(5px);
    }
  }

  .arrow-icon {
    margin-left: 8px;
    transition: transform 0.3s ease;
  }

  ${AIServiceItem} & {
    background: var(--white);
    color: var(--main-brand-color);

    &::before {
      background: var(--light-shade);
    }
  }
`;

const ModalOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 1rem;
  overflow-y: auto;
`;

const ModalContent = styled(motion.div)`
  background: var(--white);
  border-radius: 20px;
  padding: 2rem;
  max-width: 800px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  margin: 2rem;

  @media (max-width: 768px) {
    margin: 1rem;
    padding: 1.5rem;
  }

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--main-brand-color);
    border-radius: 3px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: var(--dark-shade);
  font-size: 1.5rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
    transform: rotate(90deg);
  }
`;

const ModalTitle = styled.h2`
  font-size: 2.5rem;
  color: var(--dark-shade);
  margin-bottom: 2rem;
  padding-right: 2rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const ModalSection = styled.div`
  margin-bottom: 2rem;
`;

const ModalSectionTitle = styled.h3`
  font-size: 1.8rem;
  color: var(--dark-accent);
  margin-bottom: 1rem;
`;

const ModalText = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--dark-shade);
  margin-bottom: 1rem;
`;

const ExampleCard = styled.div`
  background: linear-gradient(135deg, var(--main-brand-color) 0%, var(--dark-accent) 100%);
  padding: 2rem;
  border-radius: 15px;
  color: var(--white);
  margin-top: 2rem;

  ${ModalSectionTitle} {
    color: var(--white);
  }

  ${ModalText} {
    color: var(--white);
    opacity: 0.9;
  }
`;

const services = [
  {
    id: 'customer-experience',
    icon: faUsers,
    title: <>Experiencia del <span>Cliente</span></>,
    brief: "Definimos y analizamos a tu público objetivo para personalizar cada interacción, mejorando el viaje de compra y fomentando la fidelidad de tus clientes.",
    description: "",
    why: 'La experiencia del cliente es un factor clave que determina la fidelización y el crecimiento a largo plazo. Una interacción positiva genera clientes leales y fortalece tu reputación en el mercado.',
    details: 'Definimos y analizamos a tu público objetivo para personalizar cada interacción. Diseñamos estrategias que optimizan el recorrido del cliente, desde el primer contacto hasta la postventa, para asegurar una experiencia memorable.',
    benefits: [
      'Retención de clientes mejorada',
      'Incremento en el valor del cliente a largo plazo',
      'Reducción en costos de adquisición de clientes',
      'Mayor satisfacción del cliente'
    ],
    example: {
      title: 'Ejemplo Práctico',
      description: 'Integramos un sistema CRM que permite a tu equipo rastrear el historial de compras y preferencias de los clientes. Esto facilita el envío automático de recomendaciones personalizadas y ofertas exclusivas según sus intereses, fortaleciendo su relación con la marca.'
    }
  },
  {
    id: 'ai-consulting',
    icon: faBrain,
    title: <><span>Consultoría en IA</span> y Automatización</>,
    brief: "Usamos inteligencia artificial para automatizar tareas repetitivas y optimizar procesos, ayudándote a tomar decisiones estratégicas y eficientes.",
    description: "",
    isAI: true,
    why: 'La inteligencia artificial y la automatización son herramientas esenciales para reducir tareas manuales, aumentar la eficiencia y tomar decisiones estratégicas basadas en datos.',
    details: 'Implementamos soluciones de IA para automatizar tareas repetitivas, mejorar la eficiencia operativa y optimizar la toma de decisiones. Identificamos oportunidades clave para transformar procesos internos utilizando tecnología avanzada.',
    benefits: [
      'Ahorro de tiempo en tareas administrativas',
      'Respuestas más rápidas para los clientes',
      'Mejora en la productividad del equipo',
      'Procesos optimizados'
    ],
    example: {
      title: 'Ejemplo Práctico',
      description: 'Implementamos un chatbot en tu sitio web que responde automáticamente a preguntas frecuentes, como horarios de atención, estado de pedidos o información de productos. Esto libera tiempo a tu equipo para enfocarse en consultas más complejas y mejora la experiencia del cliente.'
    }
  },
  {
    id: 'marketing-sales',
    icon: faBullhorn,
    title: <><span>Marketing</span> y <span>Ventas</span></>,
    brief: "Creamos estrategias digitales y embudos de venta efectivos que aumentan tu visibilidad online y convierten más clientes.",
    description: "",
    why: 'Las estrategias digitales bien diseñadas no solo incrementan la visibilidad de tu negocio, sino que también generan leads cualificados y convierten visitantes en clientes.',
    details: 'Diseñamos estrategias integrales que incluyen SEO, creación de contenido viral y embudos de venta. Estas herramientas ayudan a captar y convertir clientes, fortaleciendo tu presencia online.',
    benefits: [
      'Mayor alcance y tráfico a tu web',
      'Generación de leads cualificados',
      'Procesos de venta más efectivos',
      'Incremento en las tasas de conversión'
    ],
    example: {
      title: 'Ejemplo Práctico',
      description: 'Creamos un embudo de ventas digital que incluye anuncios en redes sociales, una página de aterrizaje con contenido atractivo y un sistema de correos automatizados que guía a los leads desde el interés inicial hasta la compra final.'
    }
  },
  {
    id: 'advanced-tech',
    icon: faLaptop,
    title: <>Tecnología <span>Avanzada</span></>,
    brief: "Implementamos CRM, ERP, y otras herramientas digitales para mejorar la comunicación y hacer que tus procesos sean más fluidos y eficientes.",
    description: "",
    why: 'La tecnología avanzada permite a las empresas operar de manera más eficiente, centralizar datos y mejorar la toma de decisiones.',
    details: 'Integramos herramientas como CRM, ERP, sistemas de facturación y chatbots para optimizar la comunicación, la gestión de datos y los procesos internos.',
    benefits: [
      'Operaciones más fluidas y eficientes',
      'Menor riesgo de errores humanos',
      'Mejor coordinación entre departamentos',
      'Preparación para el crecimiento futuro'
    ],
    example: {
      title: 'Ejemplo Práctico',
      description: 'Implementamos un sistema ERP que centraliza la gestión de inventarios, pedidos y finanzas. Esto asegura que todas las áreas de tu empresa trabajen de manera integrada, reduciendo errores y tiempos de respuesta.'
    }
  },
  {
    id: 'agile-growth',
    icon: faChartLine,
    title: <><span>Crecimiento</span> Ágil</>,
    brief: "Aplicamos metodologías ágiles y gestión de proyectos para que tu empresa responda rápido y eficazmente a los cambios del mercado.",
    description: "",
    why: 'En un entorno empresarial cambiante, las empresas deben ser ágiles para responder rápidamente a las demandas del mercado y mantenerse competitivas.',
    details: 'Aplicamos metodologías ágiles y gestionamos proyectos a través de PMOs para asegurar que tu empresa pueda adaptarse rápidamente a nuevas oportunidades o desafíos.',
    benefits: [
      'Mayor adaptabilidad al mercado',
      'Ejecución más rápida de proyectos',
      'Mejora en la colaboración del equipo',
      'Reducción de tiempos y costos'
    ],
    example: {
      title: 'Ejemplo Práctico',
      description: 'Diseñamos un modelo de gestión basado en sprints de dos semanas para proyectos internos. Esto permite a tu equipo revisar el progreso regularmente, ajustar prioridades y mantener una comunicación clara sobre los objetivos.'
    }
  },
  {
    id: 'training-kpis',
    icon: faGraduationCap,
    title: <><span>Formación</span> y <span>KPIs</span></>,
    brief: "Capacitamos a tu equipo y establecemos KPIs claros para asegurarnos de que todos trabajen alineados con tus objetivos.",
    description: "",
    why: 'Un equipo capacitado y alineado con los objetivos del negocio es esencial para alcanzar resultados medibles y tomar decisiones informadas.',
    details: 'Ofrecemos formación especializada y establecemos indicadores clave de desempeño (KPIs) para monitorear el progreso y asegurar que tu equipo esté enfocado en los objetivos prioritarios.',
    benefits: [
      'Equipo más preparado y eficiente',
      'Mejora en la toma de decisiones estratégicas',
      'Seguimiento claro de los objetivos del negocio',
      'Incremento en la productividad general'
    ],
    example: {
      title: 'Ejemplo Práctico',
      description: 'Organizamos sesiones de capacitación para tu equipo de ventas, enseñándoles a utilizar un CRM de manera eficiente. También definimos KPIs clave, como la tasa de conversión y el tiempo promedio de respuesta, para medir su desempeño.'
    }
  }
];

const ServiceModal = ({ service, isOpen, onClose }) => {
  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  const modalVariants = {
    hidden: { 
      opacity: 0,
      y: 50,
      scale: 0.95
    },
    visible: { 
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        damping: 25,
        stiffness: 300
      }
    },
    exit: {
      opacity: 0,
      y: 50,
      scale: 0.95,
      transition: {
        duration: 0.2
      }
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <ModalOverlay
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={overlayVariants}
          onClick={onClose}
        >
          <ModalContent
            variants={modalVariants}
            onClick={e => e.stopPropagation()}
          >
            <CloseButton onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </CloseButton>
            
            <ModalTitle>{typeof service.title === 'string' ? service.title : service.title.props.children}</ModalTitle>
            
            <ModalSection>
              <ModalSectionTitle>¿Por qué es importante?</ModalSectionTitle>
              <ModalText>{service.why}</ModalText>
            </ModalSection>

            <ModalSection>
              <ModalSectionTitle>¿Qué hacemos?</ModalSectionTitle>
              <ModalText>{service.details}</ModalText>
            </ModalSection>

            <ModalSection>
              <ModalSectionTitle>Beneficios</ModalSectionTitle>
              {service.benefits.map((benefit, index) => (
                <ModalText key={index}>• {benefit}</ModalText>
              ))}
            </ModalSection>

            <ExampleCard>
              <ModalSectionTitle>{service.example.title}</ModalSectionTitle>
              <ModalText>{service.example.description}</ModalText>
            </ExampleCard>
          </ModalContent>
        </ModalOverlay>
      )}
    </AnimatePresence>
  );
};

const Services = () => {
  const [selectedService, setSelectedService] = useState(null);

  const handleOpenModal = (service) => {
    setSelectedService(service);
  };

  const handleCloseModal = () => {
    setSelectedService(null);
  };

  return (
    <ServicesSection>
      <SectionTitle>Nuestros Servicios</SectionTitle>
      <ServicesGrid>
        {services.map((service, index) => (
          service.isAI ? (
            <AIServiceItem key={index} className="service-item">
              <IconWrapper>
                <FontAwesomeIcon icon={service.icon} />
              </IconWrapper>
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceBrief>{service.brief}</ServiceBrief>
              <ServiceDescription>{service.description}</ServiceDescription>
              <LearnMoreButton onClick={() => handleOpenModal(service)}>
                ¿Por qué es importante?
                <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
              </LearnMoreButton>
            </AIServiceItem>
          ) : (
            <ServiceItem key={index} className="service-item">
              <IconWrapper>
                <FontAwesomeIcon icon={service.icon} />
              </IconWrapper>
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceBrief>{service.brief}</ServiceBrief>
              <ServiceDescription>{service.description}</ServiceDescription>
              <LearnMoreButton onClick={() => handleOpenModal(service)}>
                ¿Por qué es importante?
                <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
              </LearnMoreButton>
            </ServiceItem>
          )
        ))}
      </ServicesGrid>

      <ServiceModal
        service={selectedService || services[0]}
        isOpen={selectedService !== null}
        onClose={handleCloseModal}
      />
    </ServicesSection>
  );
};

export default Services;
