import React, { useRef } from 'react';
import styled from 'styled-components';
import AboutUs from '../components/AboutUs';
import DiagnosticQuestionnaire from '../components/DiagnosticQuestionnaire';

const AboutUsContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const Section = styled.section`
  margin-bottom: 3rem;
  text-align: center;
`;

const SectionTitle = styled.h2`
  color: var(--dark-accent);
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const SubSectionTitle = styled.h3`
  color: var(--main-brand-color);
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const Paragraph = styled.p`
  color: var(--text-color);
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const CTAButton = styled.button`
  background-color: var(--main-brand-color);
  color: var(--white);
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--dark-accent);
  }
`;

const AboutUsPage = () => {
  const questionnaireRef = useRef(null);

  const scrollToQuestionnaire = () => {
    questionnaireRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <AboutUsContainer>
      <Section>
        <SectionTitle>Nuestro propósito</SectionTitle>
        <Paragraph>
          En <strong>VisionaryOps</strong>, estamos aquí para acompañar a PYMES, startups y emprendedores en su viaje hacia la digitalización. Nuestro propósito es simplificar y optimizar sus operaciones, haciendo que la tecnología trabaje a su favor, con soluciones personalizadas y un toque cercano. Además, creamos embudos de venta efectivos que no solo generan más tráfico y ventas, sino que también ayudan a construir relaciones duraderas con los clientes. Nuestro enfoque es que tu negocio crezca, de manera sostenible y sencilla.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>Misión, visión, valores</SectionTitle>
        <SubSectionTitle>Nuestra Misión</SubSectionTitle>
        <Paragraph>
          En <strong>VisionaryOps</strong>, nuestra misión es impulsar la transformación digital de pequeñas y medianas empresas, startups y emprendedores, ayudándoles a crecer mediante la implementación de tecnología avanzada y estrategias personalizadas de embudos de venta que generan resultados medibles y sostenibles.
        </Paragraph>
        <SubSectionTitle>Nuestra Visión</SubSectionTitle>
        <Paragraph>
          Queremos ser el socio estratégico de referencia para empresas que buscan innovar y adaptarse al mercado digital, ayudándoles a alcanzar su máximo potencial mediante soluciones creativas y tecnológicas.
        </Paragraph>
        <SubSectionTitle>Nuestros Valores</SubSectionTitle>
        <Paragraph>
          Nos guiamos por la cercanía, la transparencia y la innovación. Valoramos las relaciones humanas, ofreciendo siempre soluciones personalizadas y eficientes, que ayuden a nuestros clientes a crecer de manera sostenible y genuina.
        </Paragraph>
      </Section>

      {/* <Section>
        <SectionTitle>Clientes que ya confían en nosotros</SectionTitle>
        <SuccessStories />
      </Section> */}

      <AboutUs/>

      <Section ref={questionnaireRef}>
        <SectionTitle>¿Listo para transformar tu negocio?</SectionTitle>
        <CTAButton onClick={scrollToQuestionnaire}>Comienza tu diagnóstico gratuito</CTAButton>
        <DiagnosticQuestionnaire />
      </Section>
    </AboutUsContainer>
  );
};

export default AboutUsPage;